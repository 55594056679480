import React from 'react';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default  ({
  image,
  title,
  text,
  layout = {
    xs: 12,
    sm: 12,
    md: 4,
    lg: 4,
    xl: 4
  }
}) => (
  <Grid item container {...layout}
    alignContent='flex-start'
    justify='flex-start'
    alignItems='flex-start'
    className={styles.templateContainer}>
    <div className={styles.imageWrapper}>
      <Img objectFit='contain'
        className={styles.imageContainer}
        fluid={image.fluid}/>
    </div>
    <Typography variant='h4'
      align='left'
      color='dark'
      className={styles.templateTitleContainer}>
      {title}
    </Typography>
    <Typography variant='subtitle2' weight='light'
      color='dark'
      align='left'
      className={styles.templateTextContainer}>
      {text}
    </Typography>
  </Grid>
);