import React from 'react';
import Content from '../views/Leasing/Features';

export default ({location}) => <Content location={location} seo={{
  title: "Datadrevet løsning til køretøjshåndtering for leasingselskaber.",
  lang: 'da-DK',
  description: 'En digital løsning til leasingselskaber, der leveres af relevante køretøjsdata. Adgang til data om kilometertal, service-vedligeholdelse,køretøjsstatus, osv.',
  meta: [{
    name: 'keywords',
    content: 'Løsning til leasingselskaber'
  }]
}}/>