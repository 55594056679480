import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        background: imageSharp (fluid: { originalName: { eq: "About-Us-Hero.png" }}) {
          fluid(maxWidth: 2400, maxHeight: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        hero: imageSharp (fluid: { originalName: { eq: "Leasing-Features-Hero.jpg" }}) {
          fluid(maxWidth: 1600, maxHeight: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ background, hero }) => (
      <Grid container justify='space-between'
        className={styles.container}>
        <Grid item className={styles.backgroundContainer}
          xs={12} sm={12} md={12}>
          <Img imgStyle={{ objectFit: 'cover' }}
            objectFit='cover'
            className={styles.imageContainer}
            fluid={background.fluid}
            alt='OBI+ Fleet'/>
        </Grid>
        <Grid item className={styles.splashContainer}
          xs={12} sm={12} md={6} lg={6} xl={6}>
          <Img imgStyle={{ objectFit: 'contain' }}
            objectFit='cover'
            className={styles.imageContainer}
            fluid={hero.fluid}
            alt='OBI+ Fleet'/>
        </Grid>
        <Grid item container
          xs={12} sm={12} md={6} lg={6}
          alignContent='center'
          className={styles.overviewContainer}>
          <Typography variant='h1'
            color='dark'
            weight='regular'
            className={styles.title}>
            {i18n('A solution that makes vehicle management easier for leasing companies')}
          </Typography>
        </Grid>
      </Grid>
    )}/>
);