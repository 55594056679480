import React from 'react';
import 'whatwg-fetch';
import { Grid } from '@material-ui/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../locale';
import Typography from '../../components/Typography';
import SectionWrapper from '../../components/SectionWrapper';
import Button from '../../components/Button';
import Check from './Check';
import ErrorMessage from './ErrorMessage';
import styles from './styles.module.sass';

const postToForm = email => fetch(
'https://api.hsforms.com/submissions/v3/integration/submit/5142926/856c0f88-d86c-464d-80c1-a3bf3727f269',
{
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    'submittedAt': Date.now(),
    'fields': [{
        'name' : 'email',
        'value': email
      }
    ],
    'context': {
      'pageUri': i18n('_url:_'),
      'pageName': 'Call to action sign-up'
    },
    'legalConsentOptions': {
      "consent": {
        "consentToProcess": true,
        "text": "I agree to allow OBI+ to store and process my personal data.",
        "communications": [
          {
            "value": true,
            "subscriptionTypeId": 999,
            "text": "I agree to receive marketing communications from OBI+."
          }
        ]
      }
    }
  })
})

export default () => {
  const [ submitted, setSubmitted ] = React.useState(false);
  const [ email, setEmail ] = React.useState('');
  const [ error, setError ] = React.useState(false);

  return (
    <StaticQuery
      query={graphql`
        query {
          background: imageSharp (fluid: {
            originalName: { eq: "About-Us-Hero.png" }
          }) {
            fluid(maxWidth: 2400, maxHeight: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      `}
      render={({ background }) => (
        <SectionWrapper className={styles.container}>
          <Grid item className={styles.splashContainer}
            xs={12} sm={12} md={12} lg={12}>
            <Img imgStyle={{ objectFit: 'cover' }}
              objectFit='cover'
              className={styles.imageContainer}
              fluid={background.fluid}
              alt='OBI+ Fleet'/>
          </Grid>
          {!submitted
          ? <Grid item xs={12} md={12} className={styles.overviewContainer}>
              <Typography
                variant='h3'
                color='white'
                weight='bold'
                align='center'
                className={styles.title}>
                {i18n('Get started with OBI+ today')}
              </Typography>   
              <div className={styles.emailContainer}>
                <div className={styles.inputContainer}>
                  <input type='email' className={styles.input}
                    defaultValue=''
                    onChange={e => setEmail(e.target.value)}
                    placeholder={i18n('Email address')}/>
                  <Grid container className={styles.checksContainerWide}>
                    <Check text={i18n('Product tour')} xs={4} sm={4} md={4} lg={4} xl={4}/>
                    <Check text={i18n('Consultation')}/>
                    <Check text={i18n('Trial')}/>
                  </Grid>
                </div>
                <div className={styles.buttonContainer}>
                  <Button className={styles.button}
                    onClick={() => postToForm(email)
                      .then(res => res.json()).then(res => {
                        if(res.status !== 'error') {
                          setError(false);
                          setSubmitted(true);
                        } else setError(true);
                      }).catch(() => setError(true))}>
                    <Typography variant='subtitle1'
                      weight='bold'
                      color='white'
                      align='center'>
                      {i18n('Get started')}
                    </Typography>
                  </Button>
                </div>
                <Grid container className={styles.checksContainerNarrow}>
                  <Check text={i18n('Product tour')} xs={4} sm={4} md={4} lg={4} xl={4}/>
                  <Check text={i18n('Consultation')}/>
                  <Check text={i18n('Trial')}/>
                </Grid>
              </div>
              <ErrorMessage error={error}/>
            </Grid>
          : <Grid item xs={12} md={12} className={styles.overviewContainer}>
              <Typography
                variant='h4'
                color='white'
                weight='bold'
                align='center'
                className={styles.thankYou}>
                {i18n('Perfect! We will contact you soon.')}
              </Typography>
            </Grid>}
        </SectionWrapper>
      )
    }/>
  )
};
