import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.sass';

export default ({ expanded = false }) => (
  <div className={clsx(
    styles.iconWrap,
    expanded ? styles.iconWrapExpanded : ''
  )}>

  </div>
);