import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandIcon from './ExpandIcon';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default ({
  summary,
  descriptions, 
  expanded = false,
  onChange 
}) => (
  <ExpansionPanel
    elevation={0}
    expanded={expanded} 
    onChange={onChange}
    classes={{
      root: styles.questionContainer,
      expanded: styles.questionContainerExpanded
    }}>
    <ExpansionPanelSummary
      classes={{
        root: styles.questionSummaryContainer,
        content: styles.questionSummaryContent,
        expandIcon: styles.questionSummaryIcon
      }}
      aria-controls={`question-${summary}-content`}
      id={`question-${summary}-header`}
      expandIcon={<ExpandIcon expanded={expanded}/>}>
      <Typography variant='h4' 
        align='left'
        color='dark'>
        {summary}
      </Typography>
    </ExpansionPanelSummary>
    {descriptions.map((description, key) => (
      <ExpansionPanelDetails key={key} 
        className={styles.questionDescription}>
        <Typography variant='subtitle2' 
          align='left'
          color='main'
          weight='light'>
          {description}
        </Typography>
      </ExpansionPanelDetails>
    ))}
  </ExpansionPanel>
);